$('.js-show-grid').on('click', function() {
  $(this)
    .closest('.table_grid__wrapper')
    .find('.table_grid__item.hidden')
    .css('display', 'flex')
    .hide()
    .fadeIn('slow')
    .removeClass('hidden')
    .end()
    .end()
    .remove()
})
