import cssVars from 'css-vars-ponyfill'
import WOW from 'wow.js'
import stickElement from '../util/stickit'

export default {
  init() {
    // JavaScript to be fired on all pages
    this.toggleTeaserContent()
    this.setBodyWidth()
    this.megaMenuListeners()

    if (window.location.hash) {
      this.smoothScroll(window.location.hash)
    }

    $('.js-search-toggle', document).on('click', this.toggleSearch)

    $('#search-modal', document).on('hidden.bs.modal', this.toggleSearch)

    $('.js-close-infobar', document).on('click', e => {
      e.preventDefault()
      this.toggleInfobar()
    })

    this.onResize()
    this.scrollThrough()
    this.sitemapHandler()
    this.gfieldButtonsRemove()
    this.wowAnimation()
    this.playButton()

    $(document).on(
      'click',
      'a[href^="#"]:not([href="#"],[href="#0"],[href^="#fancy-"],[data-toggle],[role="tab"],[data-vc-accordion],[data-vc-tabs])',
      e => {
        this.smoothScroll($(e.target).attr('href'), e)
      }
    )

    $(window).scroll(() => {
      $('.scrollToTop')[
        ($(window).scrollTop() > 300 ? 'add' : 'remove') + 'Class'
      ]('visible')
    })

    $('.scrollToTop').click(function() {
      $('html, body').animate({ scrollTop: 0 }, 1000)
      return false
    })
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    stickElement('header.banner:not(.pos-a)', {
      holderClass: 'banner__holder',
    })
    // this.megaMenuListeners()
  },
  searchIsEnabled: false,
  throttled(delay, fn) {
    let lastCall = 0
    return function(...args) {
      const now = new Date().getTime()
      if (now - lastCall < delay) {
        return
      }
      lastCall = now
      return fn(...args)
    }
  },
  setBodyWidth() {
    cssVars({
      variables: { '--body-width': document.body.clientWidth + 'px' },
    })
  },
  smoothScroll(link, e) {
    let destination = 0

    if (e) e.preventDefault()
    var hash = false

    if (Number.isInteger(link)) {
      hash = false
      destination = link
    } else if (!link && window.location.hash) {
      hash = window.location.hash
    } else if (link && link.indexOf('#') == 0) {
      hash = link
    } else if (
      link &&
      location.pathname.replace(/^\//, '') ==
        link.pathname.replace(/^\//, '') &&
      location.hostname == link.hostname
    ) {
      hash = link.hash
    }

    if (hash && $(hash).length) {
      destination = $(hash).offset().top - 75
      history.replaceState(null, null, hash)
    }

    $('html, body').animate(
      {
        scrollTop: destination,
      },
      600
    )

    $(window).trigger('hashchange')
  },
  onResize() {
    $(window).on('resize', () => {
      this.setBodyWidth()
      this.toggleTeaserContent()
    })
  },
  megaMenuListeners() {
    $(window).load(() => {
      $('li.mega-menu-item').on('mouseover', function() {
        $(
          '#mega-menu-primary_navigation > li.mega-menu-item > a.mega-menu-link'
        ).css('opacity', 0.6)
        $(this)
          .find('> a.mega-menu-link')
          .css('opacity', 1)
      })
      $('li.mega-menu-item').on('mouseout', function() {
        $(
          '#mega-menu-primary_navigation > li.mega-menu-item > a.mega-menu-link'
        ).css('opacity', 1)
      })
      $('li.mega-menu-item')
        .on('open_panel', () => {
          $('.banner').addClass('is-active')
          if ($('.dhsv_vc_heeader_slider').length) {
            $('.dhsv_vc_heeader_slider')
              .find('video')
              .each(function() {
                $(this)
                  .get(0)
                  .pause()
              })
          }
        })
        .on('close_panel', () => {
          $('.banner').removeClass('is-active')
          if ($('.dhsv_vc_heeader_slider').length) {
            $('.dhsv_vc_heeader_slider')
              .find('video')
              .each(function() {
                $(this)
                  .get(0)
                  .play()
              })
          }
        })
    })
  },
  toggleSearch(e = false) {
    e && e.preventDefault()
    this.searchIsEnabled = !$('body').hasClass('search-is-opened')
    $('body')[(this.searchIsEnabled ? 'add' : 'remove') + 'Class'](
      'search-is-opened'
    )
    $('#search-modal').modal(this.searchIsEnabled ? 'show' : 'hide')
    setTimeout(() => {
      $('.search-field').focus()
    }, 500)
    // $('.js-searchform')['slide' + (this.searchIsEnabled ? 'Down' : 'Up')]()
  },
  toggleInfobar() {
    $('.js-infobar').slideUp()
    document.cookie = 'infobar_rejected=true'
  },
  scrollThrough() {
    $(document).on('click', '.js-scroll-through', function(e) {
      e.preventDefault()
      let cls = ''
      let $this = $(this)
      if ($this.closest('.vc_row').next().length) {
        cls = $this
          .closest('.vc_row')
          .next()
          .offset().top
      } else {
        cls = $this
          .closest('section')
          .next()
          .offset().top
      }
      $('html, body').animate({ scrollTop: cls }, 'slow')
    })
  },
  sitemapHandler() {
    $('.js-sitemap-handler', document).on('click', e => {
      e.preventDefault()
      $('.js-sitemap-handler').toggleClass('is-active')
      $('.js-sitemap-container').slideToggle()

      if (!$(this).hasClass('is-active')) {
        $('html, body').animate({ scrollTop: $(document).height() }, 1000)
      }
    })
  },
  toggleTeaserContent() {
    $('.teaser_item').each(function() {
      let $container = $(this).find('.content_wrapper')
      let containerHeight = $(this)
        .find('.content_wrapper')
        .outerHeight(true, true)
      let titleHeight = $(this)
        .find('.teaser_item__title')
        .outerHeight(true, true)
      $container.css('height', titleHeight)
      $(this).css('padding-bottom', titleHeight)

      if (containerHeight && $(window).width() > 767) {
        $(this)
          .mouseover(function() {
            $container.css('height', containerHeight)
          })
          .mouseout(function() {
            $container.css('height', titleHeight)
          })
      }
    })
  },
  gfieldButtonsRemove() {
    $('.gform_wrapper').each(function() {
      if ($(this).find('.gfield .btn').length > 0) {
        $(this)
          .find('.gform_footer')
          .css({
            position: 'absolute',
            left: '-9999px',
          })
        $(this).addClass('disable-footer')
      }
    })
  },
  wowAnimation() {
    const wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 200,
      mobile: false,
      live: true,
    })
    wow.init()
  },
  playButton() {
    $('.play-js').on('click', function() {
      if (
        $(this)
          .parents('.video-wrapper')
          .find('.video')
          .get(0).paused
      ) {
        $(this)
          .parents('.video-wrapper')
          .find('.video')
          .get(0)
          .play()
        $('.video-wrapper').removeClass('play')
        $(this).addClass('play')
      } else {
        $(this)
          .parents('.video-wrapper')
          .find('.video')
          .get(0)
          .pause()

        $(this).removeClass('play')
      }
    })
  },
}
