import $ from 'jquery'
import 'slick-carousel/slick/slick.min'

$(document).ready(() => {
  dhsv_vc_teaserbox_slider()
})

function dhsv_vc_teaserbox_slider() {
  $('.dhsv_vc_teaserbox_slider .teaserbox-slider').each((i, el) => {
    $(el).slick({
      dots: true,
      arrows: true,
      slidesToShow: 2,
      autoplay: false,
      autoplaySpeed: 3000,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    })
  })
}
