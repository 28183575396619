/* Don't forget to add string 'news' to function combineReducers in './posts.js' */
import { WP_API_BASE } from '../../shared/constants'

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  posts: [],
  filters: {},
  activeFilters: {
    post_tag: [],
    dhsv_post_competence: [],
    dhsv_post_department: [],
  },
  states: {
    postsShowed: 0,
    postsLoaded: 0,
    perPageLoad: 8,
    allPostsLoaded: false,
  },
}

const LOAD_POSTS_DATA = 'LOAD_POSTS_DATA'
const LOAD_POSTS_DATA_SUCCESS = 'LOAD_POSTS_DATA_SUCCESS'
const LOAD_POSTS_DATA_FAILURE = 'LOAD_POSTS_DATA_FAILURE'
const SET_POSTS_STATES = 'SET_POSTS_STATES'
const SET_POSTS_ACTIVE_FILTERS = 'SET_POSTS_ACTIVE_FILTERS'

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case LOAD_POSTS_DATA:
      return {
        ...state,
        isLoading: true,
      }

    case LOAD_POSTS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        posts: [...payload.posts],
        filters: payload.filters
          ? { ...payload.filters }
          : { ...state.filters },
      }

    case LOAD_POSTS_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      }

    case SET_POSTS_STATES:
      return {
        ...state,
        states: { ...payload },
      }

    case SET_POSTS_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilters: { ...payload },
      }

    default:
      return state
  }
}

export const loadPosts = (
  per_page,
  offset,
  firstRequest,
  filters = undefined
) => (dispatch, getState) => {
  dispatch({ type: LOAD_POSTS_DATA })

  let currentPosts = [...getState().posts.posts]
  if (currentPosts === undefined || offset === 0) {
    currentPosts = new Array()
  }

  // Rest API Params
  const params = {
    per_page: per_page,
    offset: offset,
    firstRequest: firstRequest,
  }

  let apiBaseUrl = WP_API_BASE
  // const format = window.siteLang.format
  // if (parseInt(format) === 3) {
  //   params.lang = window.siteLang.current
  // } else if (window.siteLang.current !== window.siteLang.default) {
  //   apiBaseUrl = '/' + window.siteLang.current + apiBaseUrl
  // }

  if (filters !== undefined) {
    Object.keys(filters).map(key => {
      if (filters[key].length) {
        params[key] = filters[key].join(',')
      }
    })
  }

  let url_params = '/'
  if (params) {
    let i = 0
    for (let key in params) {
      params.hasOwnProperty(key)
        ? (url_params += (i === 0 ? '?' : '&') + key + '=' + params[key])
        : false
      i++
    }
  }

  return fetch(`${apiBaseUrl}posts${url_params}`, {
    method: 'GET',
  })
    .then(response => {
      if (response.ok && response.status === 200) {
        return response.json()
      }
      throw new Error(response.statusText)
    })
    .then(response => {
      let payload = {
        posts: response.posts.length
          ? [...currentPosts, ...response.posts]
          : currentPosts,
        filters: response.filters && { ...response.filters },
      }

      dispatch({
        payload,
        type: LOAD_POSTS_DATA_SUCCESS,
      })

      if (filters !== undefined) {
        const postsLength = response.posts.length
        dispatch({
          payload: {
            postsLoaded: postsLength,
            postsShowed: postsLength >= 7 ? 7 : postsLength,
            allPostsLoaded: postsLength <= 7 ? true : false,
            perPageLoad: 6,
          },
          type: SET_POSTS_STATES,
        })

        dispatch({
          payload: filters,
          type: SET_POSTS_ACTIVE_FILTERS,
        })
      }

      return response
    })
    .catch(error => {
      if (process.env.NODE_ENV === 'development') {
        console.error(error) //eslint-disable-line
      }
      dispatch({
        error: error,
        type: LOAD_POSTS_DATA_FAILURE,
      })
    })
}

export const changeStates = states => dispatch => {
  return dispatch({
    type: SET_POSTS_STATES,
    payload: states,
  })
}
