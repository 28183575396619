import { combineReducers } from 'redux'
import browser from './browser'
import router from './router'
import posts from './posts'
import stories from './stories'
import updates from './updates'
import events from './events'

const appReducer = combineReducers({
  browser,
  location: router,
  posts,
  stories,
  updates,
  events,
})

export default appReducer
