import $ from 'jquery'
import 'slick-carousel/slick/slick.min'

$(document).ready(() => {
  dhsv_vc_testimonial_slider()
})

function dhsv_vc_testimonial_slider() {
  $('.dhsv_vc_testimonials_slider .testimonials-slider').each((i, el) => {
    $(el).slick({
      dots: true,
      arrows: true,
      slidesToShow: $(el).data('slides'),
      // centerMode: true,
      // centerPadding: '17%',
      autoplay: true,
      autoplaySpeed: 10000,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 1439,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 767,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            centerPadding: '15px',
          },
        },
      ],
    })
  })
}

if ($('.dhsv_vc_testimonials_slider').length) {
  $(window).on('load', function() {
    const ContainerOffset = $('.dhsv_vc_testimonials_slider').offset().left
    const mainContainer = document.querySelector('.dhsv_vc_testimonials_slider')
    let hoverArea = mainContainer.clientWidth / 3
    mainContainer.addEventListener('mousemove', runEvent)
    function runEvent(e) {
      let relX = e.x
      if (
        relX - ContainerOffset < hoverArea ||
        relX - ContainerOffset > hoverArea * 2
      ) {
        $(this).addClass('show_arrows')
      } else {
        $(this).removeClass('show_arrows')
      }
    }
    mainContainer.addEventListener('mouseout', function() {
      $(this).removeClass('show_arrows')
    })
  })
}
