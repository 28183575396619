'use strict'
// TODO: add an option to stick element within the container

/* eslint-disable no-console */

let stickOffset = 0
let stickClass = 'set-sticky'
let stickInterval
// let scrollDirection = false
let defaultOptions = {
  holderClass: '',
  withinContainer: false,
}

/**
 * stickElement
 * @param  {string} selector jquery selector
 * @param  {object} userOptions
 * @return void
 */
export default function stickElement(selector, userOptions = {}) {
  const options = { ...defaultOptions, ...userOptions }
  setStick(selector, options)
  $(window).on('scroll resize', function(event) {
    setStick(selector, options, event)
  })
  // $(window).on('scroll resize', function(event) {
  //   throttle(setStick, 10, [selector, options, event])
  // })
}

const setStick = (selector, options, event) => {
  $(selector).each(function(index, el) {
    // Get holder element if there is one
    let $holder = $(el).closest('[class*="__holder"]')
    if (!$holder.length) {
      // If no holder found, create and set parameters
      $(el).wrap(
        `<div class="set-sticky__holder ${options.holderClass}"></div>`
      )
      $holder = $(el).closest('[class*="__holder"]')
      $holder.css({
        marginTop: $(el).css('margin-top'),
        marginBottom: $(el).css('margin-bottom'),
      })
    }

    // Make sticky element the same width as the holder
    $(el).css({
      width: $holder.width(),
    })

    // Set required data-attributes to the sticky element if it doesn't have it yet
    if (
      typeof $(el).data('initial-offset') == 'undefined' ||
      $(el).data('initial-offset') != 0
    ) {
      $(el).data('initial-offset', $holder.offset().top)
    }

    if (!$(el).data('initial-height')) {
      $(el).data('initial-height', $(el).outerHeight())
      setHolderHeight($(el), $holder)
    }

    // Recalculate initial offset and initial height of the element on resize event
    if (typeof event !== 'undefined' && event.type == 'resize') {
      $(el).data('initial-offset', $holder.offset().top)

      if ($(el).data('initial-height') != $(el).outerHeight()) {
        $(el).data('initial-height', $(el).outerHeight())
        setHolderHeight($(el), $holder)
      }
    }

    // Stick element within it's container
    if (options.withinContainer == true && $(el).hasClass(stickClass)) {
      let $parentContainer = $holder.length ? $holder.parent() : $(el).parent()
      let parentBottomPosition =
        $parentContainer.offset().top + $parentContainer.outerHeight()
      $(el).data('freezeAt', parentBottomPosition)
      console.log(stickOffset) // eslint-disable-line

      if (parentBottomPosition <= $(window).scrollTop()) {
        $(el)
          .addClass('freeze')
          .removeClass('freezing')
        console.log('set freeze') // eslint-disable-line
      } else if ($(window).scrollTop() + stickOffset > parentBottomPosition) {
        $(el)
          .addClass('freezing')
          .removeClass('freeze')
        console.log('set freezing') // eslint-disable-line
        // console.log('stop stick at ', parentBottomPosition) // eslint-disable-line
      } else if (
        $(window).scrollTop() + stickOffset <= parentBottomPosition &&
        !$(el).hasClass('freeze') &&
        $(el).hasClass('freezing')
      ) {
        $(el).removeClass('freezing')
        console.log('remove freezing') // eslint-disable-line
      }
      // let stillInContainer = $(window).scrollTop() + stickOffset > $(el).data('initial-offset')

      // $(el).data('stillInContainer', true)
    }

    // Check if element is reached the stick point
    if (
      $(window).scrollTop() + stickOffset > $(el).data('initial-offset') &&
      !$(el).hasClass(stickClass)
    ) {
      $(el)
        .addClass(stickClass)
        .css({
          marginTop: 0,
          marginBottom: 0,
          position: 'fixed',
        })
      console.log('stick') // eslint-disable-line
      // updateStickOffset()
    } else if (
      $(window).scrollTop() + stickOffset - $(el).data('actual-height') <=
        $(el).data('initial-offset') &&
      $(el).hasClass(stickClass)
    ) {
      $(el)
        .removeClass(stickClass)
        .css({
          marginTop: '',
          marginBottom: '',
          position: '',
          top: '',
        })
      console.log('unstick') // eslint-disable-line
    }
    // console.log($(el).data('initial-offset')) //eslint-disable-line
  })

  if (typeof stickInterval === 'undefined') {
    stickInterval = setInterval(function() {
      updateStickOffset()
    }, 15)
    // window.requestAnimationFrame(updateStickOffset)
  }
}

const setHolderHeight = ($el, $holder) => {
  $holder.css({
    height: $el.data('initial-height'),
  })
}

const updateStickOffset = () => {
  stickOffset = 0
  $('.set-sticky').each(function(index, el) {
    $(el).data('actual-height', $(el).outerHeight())
    let freezeAt = $(el).data('freezeAt')
    let topOffset = stickOffset
    if ($(el).hasClass('freezing')) {
      // console.log('is freezing') // eslint-disable-line
      topOffset = freezeAt - $(window).scrollTop() - $(el).outerHeight()
    } else if ($(el).hasClass('freeze')) {
      topOffset = -$(el).outerHeight()
    }

    $(el).css({
      top: topOffset,
    })

    let visiblePart =
      $(el).offset().top +
      $(el).outerHeight() -
      $(window).scrollTop() -
      stickOffset
    if ($(el).hasClass('freezing')) {
      console.log('visible', visiblePart > 0 ? visiblePart : 0) // eslint-disable-line
    }
    stickOffset += visiblePart > 0 ? visiblePart : 0

    // stickOffset = Math.max(
    //   $(el).offset().top - $(window).scrollTop() + $(el).outerHeight(),
    //   stickOffset
    // )
  })
  //console.log(stickOffset) // eslint-disable-line
}

// const throttle = (func, limit, args = []) => {
//   let inThrottle
//   return function() {
//     const context = this
//     if (!inThrottle) {
//       func.apply(context, args)
//       inThrottle = true
//       setTimeout(() => (inThrottle = false), limit)
//     }
//   }
// }

// Using:
// import stickElement from '../components/stickit'
// stickElement([selector])
