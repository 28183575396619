import $ from 'jquery'
import 'slick-carousel/slick/slick.min'

$(document).ready(dhsv_vc_companies_slider())

function dhsv_vc_companies_slider() {
  $('.dhsv_vc_companies_slider .companies-slider').each((i, el) => {
    $(el).slick({
      dots: true,
      arrows: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      //rows: 2,
      speed: 500,
      // responsive: [
      //   {
      //     breakpoint: 1239,
      //     settings: {
      //       rows: 1,
      //       slidesToShow: 2,
      //       slidesToScroll: 2,
      //     },
      //   },
      //   {
      //     breakpoint: 767,
      //     settings: {
      //       rows: 1,
      //       slidesToShow: 1,
      //       slidesToScroll: 1,
      //     },
      //   },
      // ],
    })
  })
}
