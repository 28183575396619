import React from 'react'
import PropTypes from 'prop-types'

class Marker extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { link } = this.props
    return (
      <div className="marker">
        <a href={link} target={`_blank`}>
          <div className="pin" />
          <div className="pulse" />
        </a>
      </div>
    )
  }
}

Marker.propTypes = {
  link: PropTypes.string,
}

export default Marker
