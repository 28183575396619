$(window).on('load', function() {
  if ($('.dhsv_vc_timeline').length) {
    const mainContainer = document.querySelector('.dhsv_vc_timeline')
    let hoverArea = mainContainer.clientWidth / 3
    mainContainer.addEventListener('mousemove', function(e) {
      let relX = e.x
      if (relX < hoverArea || relX > hoverArea * 2) {
        $(this).addClass('show_arrows')
      } else {
        $(this).removeClass('show_arrows')
      }
    })
    mainContainer.addEventListener('mouseout', function() {
      $(this).removeClass('show_arrows')
    })
  }
})
