import $ from 'jquery'
import 'slick-carousel/slick/slick.min'

$(document).ready(() => {
  dhsv_vc_header_slider()
  fixedBanner()
})

function dhsv_vc_header_slider() {
  if ($('.dhsv_vc_heeader_slider').length) {
    $('body').addClass('body-active with_header_slider')
    $('header.banner').addClass('pos-a')
  }

  $('.dhsv_vc_heeader_slider .header-slider').each(function(i, el) {
    $(el).slick({
      dots: false,
      arrows: true,
      slidesToShow: 1,
      infinite: true,
      fade: true,
      speed: 500,
      cssEase: 'linear',
    })
  })

  $('.dhsv_vc_heeader_slider .header-slider-mobile').each(function(i, el) {
    $(el).slick({
      dots: false,
      arrows: true,
      slidesToShow: 1,
      infinite: true,
      fade: true,
      speed: 500,
      cssEase: 'linear',
      autoplay: true,
      autoplaySpeed: 5000,
      slide: '.header-slider__item',
    })
  })
}

let fixedBanner = function() {
  if ($('.dhsv_vc_heeader_slider').length) {
    $(window).on('scroll', function() {
      let scroll = $(window).scrollTop()
      let topBarHeight = $('.topbar').outerHeight(true, true)
      if (scroll > topBarHeight) {
        $('body').addClass('banner_fixed')
        $('header.banner').addClass('set-sticky')
      } else {
        $('body').removeClass('banner_fixed')
        $('header.banner').removeClass('set-sticky')
      }
    })
  }
}
