import $ from 'jquery'

$(document).ready(dhsv_vc_accordion())

function dhsv_vc_accordion() {
  $('.dhsv_vc_tabs_content').each(function() {
    $(this)
      .find('.nav-tabs--item')
      .on('click', function() {
        let ind = $(this).index()
        if ($(this).hasClass('active')) {
          $(this).removeClass('active')
        } else {
          $(this)
            .parents('.nav-tabs')
            .find('.nav-tabs--item')
            .removeClass('active')
          $(this).addClass('active')
          $(this)
            .parents('.dhsv_vc_tabs_content')
            .find('.tab-pane')
            .removeClass('active')
          $(this)
            .parents('.dhsv_vc_tabs_content')
            .find('.tab-content .tab-pane')
            .eq(ind)
            .addClass('active')
        }
      })
  })
}
